<template>
  <v-dialog 
    v-model="dialog"
    max-width="500" 
    persistent
  >
    <v-card>
      <v-card-title class="primary white--text" color="secondary">
        Modify Key
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-row class="mt-3">
          <v-col cols="12">
            <p><strong>WARNING:</strong> Updating permissions on your API key could incur additional charges for the API key as per our pricing policy.</p>
            <p class="advisory-text">*Modifications to your API key may take a few minutes to take effect. If the changes are not visible please refresh the API key list.</p>
            <p><strong>You are modifying the following key:</strong></p>
            <p>API Key: <strong>{{apikey.Key}}</strong></p>
            <p>shortName: <strong>{{apikey.ShortName}}</strong></p>
          </v-col>
        </v-row>
        <v-row class="mt-3">
        <div>
      <p>Please select the permissions you would like on your API key. Each key will come with access to the advertising API as standard and should be enough to build your marketing website. Additional charges will apply to additional permissions.</p>
    <v-checkbox class="permission-checkbox" v-for="permission in permissions" :key="permission.TierPattern"
      :label="permission.TierName"
      :value = stripOutShortName(permission.TierPattern)
      v-model="selectedPermissions"
    ></v-checkbox>
    <p>Please use the additional information tab to record any additional information you feel might be relevant to help identifying the purpose of your new internal API key in the future.</p>
    <v-textarea v-model="additionalNotes" label="Additional information"></v-textarea>
      </div>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red d-inline" text @click="closeDialog()">Cancel</v-btn>
        <v-btn
          color="green d-inline"
          width="100"
          class="mr-3 white--text"
          @click="updateKey()"
        >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: [
    'dialog',
    'apikey'
    ],
  data() {
    return {
      selectedPermissions: this.apikey.Permissions,
      additionalNotes : this.apikey.AdditionalNotes
    }
  },
  computed: {
    ...mapGetters({
      permissions : 'Permissions/getPermissions'
    })
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
    },
    updateKey(){
      const payload =
      {
        ApiKey : this.apikey.Key,
        Permissions: this.selectedPermissions,
        AdditionalNotes : this.additionalNotes
      }
      this.$store.dispatch("Keys/updateKey", payload)
        this.$emit("closeDialog")
    },
    stripOutShortName(permissionstring){
      return permissionstring.replace("/" + this.apikey.ShortName, "");
    }
  }
}
</script>
<style>
.permission-checkbox{
  text-align: center;
}
.advisory-text{
  color:grey;
  font-size: 10pt;
}
</style>