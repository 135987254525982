<template>
  <div class="home">
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex lg9>
          <ConfirmLogin />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import ConfirmLogin from '../components/ConfirmLogin.vue'
export default {
  components:{
    ConfirmLogin
  },
  data() {
    return {
    }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
  /* Images from https://www.pexels.com/ */
  background-position: center;
  background-size: cover;
}
</style>