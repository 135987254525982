import Vue from 'vue'
import Vuex from 'vuex'
import { API } from 'aws-amplify'

// modules
import Keys from './modules/Keys/Keys'
import Company from './modules/Company/Company'
import Permissions from './modules/Permissions/Permissions'

import { authAgentOSSignIn, authAgentOSSignConfirm ,authAgentOSSignOut, authCurrentSignedInUserGroup} from './agentosAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    errorMessages: [],
    loginConfirmed: false,
    confirmPricing : false,
    username: null,
    loginFailed: false
  },
  mutations: {
    SET_LOGIN_FAILED(state, data){
      state.loginFailed = data;
    },
    SET_ERROR(state, data) {
      state.errorMessages.push(data)
    },
    SET_USER_DATA(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      authAgentOSSignIn(userData.UserOID, userData.UserDisplayName, userData.UserToken);
    },
    CLEAR_USER_DATA(state) {
      state.user = null,
      state.username = null
      state.errorMessages = [],

      localStorage.removeItem('user')
      
      authAgentOSSignOut();
    },
    SET_CONFIRMED_LOGIN(state, code){
      try{
      authAgentOSSignConfirm(state.user.UserOID, code);
      }
      catch{
        throw "Invalid Code";
      }
    },
    SET_USER_NAME(state, username){
      state.username = username;
    },
    CONFIRM_PRICING(state, acknowledged){
      localStorage.setItem('confirmPricing', JSON.stringify(acknowledged))
      state.confirmPricing = acknowledged;
    }
  },
  actions: {
    login({commit}, credentials) {
      commit("CLEAR_USER_DATA");
      commit('SET_LOGIN_FAILED', false);
        const payload = {
            body: {
                Username : credentials.username,
                Password : credentials.password
            }
        };
      API.post('apiKeysMainRest', '/login', payload)
        .then( res => {
          commit('SET_USER_NAME', credentials.username)
          commit('SET_USER_DATA', res.data);
        }).catch(() => {
          commit('SET_LOGIN_FAILED', true);
        }
        );
    },
    logout({commit, state}) {
      API.post('apiKeysMainRest', `/delete?query=/${state.user.ClientName}/session/logout`)
      .then( () => {
        commit('CLEAR_USER_DATA')
      })
    },
    confirmReadPricing({dispatch, commit}){
      commit('CONFIRM_PRICING', true)
      dispatch("initialLoad");
      authCurrentSignedInUserGroup();
    },
    initialLoad({ dispatch }){
      dispatch("Company/listIntergrators")
      dispatch("Company/listBranches");
      dispatch("Permissions/listPermissions")
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.user
    },
    hasLoginFailed(state) {
      return state.loginFailed
    },
    getUsername(state){
      return state.username
    },
    getName(state){
      if(state.user !== null)
        return state.user.UserDisplayName
      else
        return "Name"
    },
    getShortname(state){
      if(state.user !== null)
        return state.user.ClientName
      else
        return "Company"
    },
    pricingApproved(state){
      return state.confirmPricing;
    }
  },
  modules: {
    Keys,
    Company,
    Permissions
  }
});


