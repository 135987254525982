<template>
    <PricingCard/>
</template>
<script>
import PricingCard from '../components/PricingCard.vue'
export default {
  components:{
    PricingCard
  },
  data() {
    return {
    }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
  /* Images from https://www.pexels.com/ */
  background-position: center;
  background-size: cover;
}
</style>
