<template>
  <div class="login-page">
    <div class="form">
      <v-row class="py-0 mt-n5">
        <v-col cols="12">
          <v-img 
            class="px-3"
            src="@/assets/agentOSLogo.png"
            contain
            height="100"
          ></v-img>
        </v-col>
        <v-col cols="12" class="my-0 d-flex justify-center align-center">
          <div class="mr-1">
              API Key app
          </div>
        </v-col>
      </v-row>
      <form class="login-form mt-3">
        <v-row>
          <v-col cols="12" class="my-0">
            <v-text-field
              outlined
              v-model="username"
              label="Username"
              append-icon="mdi-account"
              class="mb-0 pb-0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="my-n5">
            <v-text-field
              outlined
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              class="input-group--focused"
              @click:append="show = !show"
              @keyup.enter="login"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
          <v-btn
            height="50"
            class="white--text login-btn"
            :loading="disableLogin"
            :disabled="disableLogin"
            block
            color="primary"
            @click.prevent="login"
            
            >
            Log in
            </v-btn>
          </v-col>
        </v-row>

      </form>
      <div v-if="loginError" class="error-msg">
        <p v-if="invalidCredentials">Invalid username or password, or you do not have an ‘Owner’ staff level access. 
If you still can't log in, a Client Administrator on your system can reset your password or change your security level to owner
 Alternatively, please contact <a class="white--text" href="mailto:support@agentos.com">support@agentos.com</a></p>
        <p v-if="noPermission">Your user account does not have authorization to view the data contained within this app</p>
        <hr>
        <div class="error-contact-info">
          <ul id="contact-details">
            <li><a class="primary--text" href="mailto:support@agentos.com">support@agentos.com</a></li>
            <li>Call 029 2036 7960</li>
          </ul>
          <ul id="opening-times">
            <li id="opening-times-title">Opening times:</li>
            <li>Mon - Fri: 9am - 5pm</li>
            <li>Sat/Sun: closed</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  mounted(){
  },
  data() {
    return {
      btnColor: "#5580ff",
      isLoading: false,
      username: '',
      password: '',
      loginError: false,
      invalidLoginMsg: '',
      invalidCredentials: false,
      noPermission: false,
      show: false,
      isComplete:false
    }
  },
  methods:{
    login() {
      // reset data for submission:-
      this.isLoading = false;
      this.loginError = false;
      this.invalidLoginMsg = false;
      this.invalidCredentials = false;
      this.noPermission = false;
      this.show = false;
      this.isComplete = false;
      
      // submission:-
      this.isLoading = true
      this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
      })
      .then( () => {
      })
    }
  },
  watch: {
    isLoggedIn(){
      if(this.isLoggedIn === true)
        this.$router.push('/pricing');
    },
    hasLoginFailed(){

      if(this.hasLoginFailed === true)
      {
        this.isLoading = false;
        this.invalidCredentials = true
        this.password = ''        
        this.loginError = true
      }
      else{
        this.loginError = false;
      }
    }
  },
  computed:{
    ...mapGetters({
      isLoggedIn : 'loggedIn',
      hasLoginFailed : 'hasLoginFailed'
    }),
    disableLogin(){
      return this.isLoading;
    }
  }
}
</script>

<style scoped>
.login-page {
  /* border: 2px solid red; */
  min-width: 320px;
  max-width: 360px;
  padding: 8% 0 0;
  margin: 0 auto 30%;
}

.form {
  /* border: 2px solid green; */
  position: relative;
  z-index: 1;
  /* background: rgb(105,107,108, .8); */
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 20px 20px 40px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  transition: .3s ease;
}

.login-form {
  padding: 0 15px;
}

.error-msg {
  margin-top: 1rem;
  color: #eb5c37;
  font-size: .8rem;
}
  .error-contact-info {
    display: flex;
    margin-top: 1rem;
  }
    #contact-details {
      text-align: left;
    }
    #opening-times {
      list-style-type: none;
      justify-content: start;
    }
    #opening-times-title {
      font-weight: bold;
    }

</style>
