<template>
    <v-card v-if="this.$store.state.user !== undefined" class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-currency-gbp</v-icon>
                <span class="">API Key Subscriptions</span>
            </div>
        </v-card-title>
        <div class="pricing-card-content">
            <p>API Keys for Zapier and Fixflo are included in your agentOS subscriptions.</p>
            <p>All other API Keys are subject to a monthly subscription, full details can be found <a href="https://letmc.zendesk.com/hc/en-us/articles/4408138474641" target="_blank">here.</a></p>
            <p>By proceeding and activating an API Key, you are confirming that you have permission and agree to the minimum one month API subscription.</p>
        </div>
        <v-btn
          color="green"
          class="mr-3 white--text select-button"
          @click="proceed()"
          >Acknowledge</v-btn
        >
        <br />
    </v-card>
</template>
<script>
export default {
  props: [
  ],
   components: {

  },
  data() {
    return {

    }
  },
  methods:{
      proceed(){
          this.$store.dispatch("confirmReadPricing");
          this.$router.push('/keys')
      }
  }
}
</script>
<style scoped>
.pricing-card-content{
    padding:10px;
}
.select-button{
    position: relative;
    /* float: right; */
    display: block!important;
    left: 40%;
    padding:10px;
}
</style>
