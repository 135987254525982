<template>
    <div>
        <CreateKeyForm />
    </div>
</template>
<script>
import CreateKeyForm from '../components/CreateKeyForm.vue'
import {mapGetters} from 'vuex'

export default {
  components:{
    CreateKeyForm
  },
  created() {
    if(!this.pricingApproved)
      this.$router.push('/pricing');
      this.$store.dispatch("initialLoad");
  },
  computed: {
    ...mapGetters({
      pricingApproved : 'pricingApproved'
    })
  },
  data() {
    return {
    }
  },
  methods:{
      createKeyNav(){
          this.$router.push('/createkey')
      }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
  /* Images from https://www.pexels.com/ */
  background-position: center;
  background-size: cover;
}
.new-key-button{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1; 
}
</style>