import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    branches: null,
    intergrators: null
  },
  mutations: {
    SET_BRANCHES(state, data) {
      state.branches = data
    },
    SET_INTEGRATORS(state, data){
        state.intergrators = data;
    }
  },
	actions :  {

    listBranches({commit, rootState}){
        const payload = {
            headers: {
                token : rootState.user.LoginToken
            }
        };
      API.get('apiKeysMainRest', `/get?query=/${rootState.user.ClientName}/company/branches/0/1000`, payload)
      .then(res => {
        commit('SET_BRANCHES', res.data.Data)
      })
    },
    listIntergrators({commit, rootState}){
        const payload = {
            headers: {
                token : rootState.user.LoginToken
            }
        };
      API.get('apiKeysMainRest', `/get?query=/${rootState.user.ClientName}/company/intergrators`, payload)
      .then(res => {
        commit('SET_INTEGRATORS', res.data.Data)
      })
    }
  },
  getters:{
      getBranches: state => {
        return state.branches;
      },
      getIntergrators: state => {
        return state.intergrators;
      },
  }
}
