<template>
    <div>
      <!-- <v-snackbar        
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbarColor" 
      >
        {{snackbarText}}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar> -->
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-clipboard-check</v-icon>
                <span class="">Create a new API key</span>
            </div>
            </v-card-title>
              <v-form class="pa-6" lazy-validation>
                <p class="advisory-text">*Please not new API keys may take a few minutes to appear within the API key app after being created. If the key is missing please reload the API key list.</p>
      <p>Please select the integration this API key is for, if you wish to create an API key for your web developer to use to build a marketing website please select internal</p>
      <v-select v-model="intergrationPartner" :items="intergrators" item-text="IntergratorName" item-value="IntergratorEnum" label="Integration">
      </v-select>
      <div class="fixflo-questions" v-if="intergrationPartner == 'Fixflo'">
      <p>The Fixflo integration only works with one branch, please select the branch you would like the API key to integrate with.</p> 
      <v-select v-model="selectedBranchID" :items="branches" item-text="Name" item-value="OID" label="Branch">
      </v-select>
      </div>
      <div v-if="intergrationPartner == 'Internal'" class="internal-questions">
      <p>Please select the permissions you would like on your new API key. Each key will come with access to the advertising API as standard and should be enough to build your marketing website. Additional charges will apply to additional permissions.</p>
    <v-checkbox class="permission-checkbox" v-for="permission in permissions" :key="permission.TierPattern"
      :label="permission.TierName"
      :value = permission.TierPattern
      v-model="selectedPermissions"
    ></v-checkbox>
    <p>Please use the additional information tab to record any additional information you feel might be relevant to help identifying the purpose of your new internal API key in the future.</p>
    <v-textarea v-model="additionalNotes" label="Additional information"></v-textarea>
      </div>
  </v-form>
      <v-btn
          :loading="disableLogin"
          :disabled="disableLogin"
          color="green"
          class="select-button mr-3 white--text"
          @click="submit()"
          >Activate API Key</v-btn
        >
        <br />
        </v-card>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      intergrationPartner: null,
      selectedBranchID: null,
      selectedPermissions: ['v4/advertising'],
      additionalNotes: null,
      isLoading: false,
      snackbar: false,
      snackbarColor:"",
      snackbarText:"",
      timeout: 3500
    }
  },
  computed: {
    disableLogin(){
      return this.isLoading;
    },
    ...mapGetters({
      intergrators : 'Company/getIntergrators',
      branches : 'Company/getBranches',
      permissions : 'Permissions/getPermissions',
      
    })
    
  },
  methods : {
    submit(){
      this.isLoading = true;
      console.log(this.selectedPermissions);
      var payload = {
      Integration: this.intergrationPartner,
      BranchID: this.selectedBranchID,
      Permissions: this.selectedPermissions,
      AdditionalNotes: this.additionalNotes,
      }
      this.$store.dispatch("Keys/createKey", payload).then(() => {
        setTimeout(() => {
          this.$router.push('/keys')
        }, 2000)
      });
    }
  },
  watch: {
  },
}
</script>
<style scoped>
.select-button{
    position: relative;
    /* float: right; */
    display: block!important;
    left: 45%;
    padding:10px;
}
.permission-checkbox{
  width:33%;
  display: inline-block;
}
.advisory-text{
  color:grey;
  font-size: 10pt;
}
</style>