<template>
  <v-snackbar
      v-model="snackbar"
      color="red"
      fixed
      multiline
      vertical
      :timeout="timeout"
    >
    <h3>Error:</h3>
    <!-- <ul v-for="(msg, index) in errorMessages" :key="index" class="white--text">
      <li>{{msg}}</li>
    </ul> -->
    <span class="mt-3">Contact AgentOS if the issues persists.</span>
    <v-btn
      color="white"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      snackbar: true,
      timeout: 6000, 
    }
  },
  computed: {
    ...mapState(['errorMessages'])
  },
}
</script>

