<template>
  <div>
      <v-footer
        absolute
        padless
        class="grey lighten-2 font-weight-medium"
      >
        <v-col cols="12" class=" footer-column text-center" >
          
            <p class="footer-text overline my-0">{{ new Date().getFullYear() }} -&#32; agentOS API Key App</p>

        </v-col>
      </v-footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters(['getCompany'])
  },
}
</script>
<style scoped>
      @media (min-width: 1264px) {
        .footer-column{
          margin-top:10px;
          margin-left:90px;
        }
    }
    .footer-text{
      margin-bottom:10px;
      text-align: center;
      white-space: normal;
    }
    footer{
      z-index: 0;
      margin-top: 64px;
    }
</style>

