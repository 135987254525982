import { Auth } from 'aws-amplify';

/*
  Sign into Amplify Auth. If it's a new user, create it & then login
*/
export async function authAgentOSSignIn( 
  username, userDisplayName, userPassword ) {
    var user = null;
  try {
      // Attempt Sign-in:-
      user = await Auth.signIn("OID:" + String(username), String(userPassword));
  } 
  catch (error) {
    try {
      // Try creating the user first:-
      await Auth.signUp({
        username: "OID:" + String(username),
        password: String(userPassword),
        attributes:{

        }
      });
    } 
    catch(createError) {
      console.log("error");
    }
  }

  // If we have a user update their user details in Dynamo DB to match AgentOS:-
  if (user != null)
    console.log('Succesfully logged into cognito with user: ' + JSON.stringify((await Auth.currentUserInfo()).username));
}

export async function authCurrentSignedInUserGroup(){
  const user =  await Auth.currentAuthenticatedUser();
// the array of groups that the user belongs to
  return user.signInUserSession.accessToken.payload["cognito:groups"]
}

/*
  Sign out of Auth
*/
export async function authAgentOSSignOut() {
  try {
      await Auth.signOut();
  } catch (error) {
      console.log('error signing out: ', error);
  }
}