<template>
    <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-key</v-icon>
                <span class="">{{apiKey.Integration}}</span>
            </div>
        </v-card-title>
        <div class="pricing-card-content">
            <p>Api Key: <strong>{{apiKey.Key}}</strong></p>
            <p>shortName: <strong>{{apiKey.ShortName}}</strong></p>
            <p v-if="apiKey.RequestedBy != null">Requested By: <strong>{{apiKey.RequestedBy}}</strong></p>
            <p v-if="apiKey.IssueDate != null">Date Requested: <strong>{{apiKey.IssueDate}}</strong></p>
            <p v-if="apiKey.LastModifiedBy != null">Last Modified By: <strong>{{apiKey.LastModifiedBy}}</strong></p>
            <p v-if="apiKey.LastModifiedAt != null">Date Last Modified: <strong>{{apiKey.LastModifiedAt}}</strong></p>
            <p v-if="apiKey.ExpiryDate != null">Expiry Date: <strong>{{apiKey.ExpiryDate}}</strong></p>
            <div>
            <p v-if="apiKey.Integration == 'Internal Key'">Permissions:<br /><strong v-for="permission in apiKey.Permissions" :key="permission" >{{permission}} <br /></strong> </p>
            <p v-if="apiKey.Integration == 'Fixflo Key'">BranchID: <strong>{{apiKey.BranchID}}</strong></p>
            <div id="zapier" v-if="apiKey.Integration == 'Zapier Key'">
              <p>Username: <strong>{{username}}</strong></p>
              <p>Invite Link: <a href="https://zapier.com/developer/public-invite/98157/4d8a8d3142b925fae65e02d1046c3565/" target="_blank">Zapier</a></p>
            </div>
            <p v-if="apiKey.Integration == 'Internal Key'">Additonal Information:</p>
            <p v-if="apiKey.Integration == 'Internal Key'">{{apiKey.AdditionalNotes}}</p>
            </div>
        </div>
        <div class="select-button">
        <v-btn
          v-if="apiKey.Integration == 'Internal Key' && apiKey.ExpiryDate == null"
          color="green"
          class="mr-3 white--text"
          @click="openModifyPopup(apiKey)"
          >Modify</v-btn
        >
        <v-btn v-if="apiKey.ExpiryDate == null"
          color="red"
          class="mr-3 white--text"
          @click="revokeKey(apiKey.Key)"
          >Revoke</v-btn
        >
        <v-btn v-else
          color="green"
          class="mr-3 white--text"
          @click="reinstateKey(apiKey.Key)"
          >Reinstate</v-btn
        >
        </div>
        <br />
        <ModifyKeyPopup :dialog="modifyDialog"
        :apikey="apiKey"
        @closeDialog="closeModifyPopup"
        />
    </v-card>
</template>
<script>
import ModifyKeyPopup from '../components/ModifyKeyPopup.vue'
import {mapGetters} from 'vuex'

export default {
  props: [
    'apiKey'
  ],
   components: {
     ModifyKeyPopup
  },
  data() {
    return {
      modifyDialog:false,
    }
  },
  computed: {
    ...mapGetters({
      username : 'getUsername'
    })
  },
  methods:{
      proceed(){
          this.$store.dispatch("confirmReadPricing");
          this.$router.push('/keys')
      },
    openModifyPopup(){
      this.modifyDialog = false;
      this.modifyDialog = true;
    },
    closeModifyPopup() {
      this.modifyDialog = false
    },
    revokeKey(keyToRevoke){
      const payload = {
        ApiKey: keyToRevoke
      };
      this.$store.dispatch("Keys/revokeKey", payload)
    },
    reinstateKey(keyToReinstate){
      const payload = {
        ApiKey: keyToReinstate
      };
      this.$store.dispatch("Keys/reinstateKey", payload)
    }
  }
}
</script>
<style scoped>
.pricing-card-content{
    padding:10px;
}
.select-button{
    position: relative;
    /* float: right; */
    display: block!important;
    left: 35%;
    padding:10px;
}
</style>
