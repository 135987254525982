import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ConfirmSignUp from '../views/ConfirmSignUp.vue'
import KeyApp from '../views/KeyApp.vue'
import PricingInformation from '../views/PricingInformation.vue'
import Keys from '../views/Keys.vue'
import CreateKey from '../views/CreateKey.vue'
/* import AdminLogin from '../views/AdminLogin.vue'
import AdminMaster from '../views/AdminMaster.vue' */
Vue.use(VueRouter)
  const routes = [
    /* {
    path: '/admin',
    name: 'admin',
    component: AdminMaster,
    children:[{
        path:'/admin/login',
        name:'AdminLogin',
        component: AdminLogin
      }]
    }, */
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/index.html',
      component: Home,
      alias: '/'
    },
    {
      path: '/confirmsignup',
      name: 'confirmsignup',
      component: ConfirmSignUp
    },
    {
    path: '/keyapp',
    redirect: '/keyapp',
    name: 'keyapp',
    component: KeyApp,
    children: [
      {
        path: '/pricing',
        name: 'PricingInformation',
        component: PricingInformation
      },
      {
        path: '/keys',
        name: 'Keys',
        component: Keys
      },
      {
        path: '/createkey',
        name: 'CreateKey',
        component: CreateKey
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
