<template>
  <div class="home">
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex lg9>
          <Login />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import Login from '../components/Login.vue'

export default {
  components:{
    Login
  },
  data() {
    return {
    }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
}
</style>