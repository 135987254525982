<template>
  <div class="login-page">
    <div class="form">
      <v-row class="py-0 mt-n5">
        <v-col cols="12" class="my-0 d-flex justify-end align-center">
          <div class="mr-1">
              <p>Logo</p>
          </div>
        </v-col>
        <v-col cols="12">
          <p>Logo</p>
        </v-col>
      </v-row>
      <v-row class="py-0 mt-n5">
        <v-col cols="12" class="my-0 d-flex justify-end align-center">
          <div class="mr-1">
              <p class="intro-text">For your first login you will need to verify your account. A 6 digit confirmation code should have been sent to your agentOS account. After validating the account you will need to sign in again.</p>
              <p class="intro-text">Additional information of how to retrieve your 6 digit code is available here. Please note the code is only valid for 24 hours</p>
          </div>
        </v-col>
      </v-row>
      <form class="login-form mt-3">
        <v-row>
          <v-col cols="12" class="my-0">
            <v-text-field
              outlined
              v-model="confirmationCode"
              label="6 Digit Confirmation Code"
              append-icon="mdi-account"
              class="mb-0 pb-0"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
          <v-btn
            height="50"
            class="white--text"
            block
            color="primary"
            @click.prevent="confirm"
            :loading="isLoading"
            >
            Confirm Account
            </v-btn>
          </v-col>
        </v-row>
      </form>
      <div v-if="loginError" class="error-msg">
        <p>There was an issue confirming your account, please ensure the validation code was correct. If the issue persists please contact <a class="white--text" href="mailto:support@agentos.com">support@agentos.com</a></p>
        <hr>
        <div class="error-contact-info">
          <ul id="contact-details">
            <li><a class="primary--text" href="mailto:support@agentos.com">support@agentos.com</a></li>
            <li>Call 029 2036 7960</li>
          </ul>
          <ul id="opening-times">
            <li id="opening-times-title">Opening times:</li>
            <li>Mon - Fri: 9am - 5pm</li>
            <li>Sat/Sun: closed</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  mounted(){
  },
  data() {
    return {
      btnColor: "#5580ff",
      isLoading: false,
      confirmationCode: '',
      loginError: false,
      show: false,
    }
  },
  methods:{
    async confirm() {
      this.isLoading = true
      const username = "OID:" + String(this.$store.state.user.UserOID);
      const verificationCode = this.confirmationCode;

      try{
          await Auth.confirmSignUp(username, verificationCode)
          this.$router.push({ path: '/' })
      }
      catch{
          this.isLoading = false;
          this.loginError = true;
      }
    },
  }
}
</script>

<style scoped>
.intro-text{
    font-size: 10pt;
}
.login-page {
  /* border: 2px solid red; */
  min-width: 320px;
  max-width: 360px;
  padding: 8% 0 0;
  margin: 0 auto 30%;
}

.form {
  /* border: 2px solid green; */
  position: relative;
  z-index: 1;
  /* background: rgb(105,107,108, .8); */
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 20px 20px 40px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  transition: .3s ease;
}

.login-form {
  padding: 0 15px;
}

.error-msg {
  margin-top: 1rem;
  color: #eb5c37;
  font-size: .8rem;
}
  .error-contact-info {
    display: flex;
    margin-top: 1rem;
  }
    #contact-details {
      text-align: left;
    }
    #opening-times {
      list-style-type: none;
      justify-content: start;
    }
    #opening-times-title {
      font-weight: bold;
    }

</style>