<template>

  <v-app>
        <router-view></router-view>   
  </v-app>

</template>

<script>
// Autologout article:  https://css-tricks.com/detecting-inactive-users/

  window.zESettings = {
    webWidget: {
      color: {
        theme: '#FF69B4',
        launcher: '#CC3A83', // This will also update the badge
        launcherText: '#E589B7',
        button: '#8A0648',
        resultLists: '#691840',
        header: '#203D9D',
        articleLinks: '#FF4500'
      }
    }
  }
export default {
  name: 'App',
  components: {
  },
  created() {
   if(localStorage.user) {
      // console.log('Person accounts present in localStorage')
      this.$store.commit('SET_USER_DATA', JSON.parse(localStorage.user))
    }
    if(localStorage.confirmPricing){
      this.$store.commit('CONFIRM_PRICING', JSON.parse(localStorage.confirmPricing))
    }
  },
  mounted() {

  }
};
</script>

<style>
    .v-main{
      padding-top:16px !important;
      margin-left:5% !important;
      margin-right: 5% !important;
    }
    .v-application{
      background-color: #f5f5f5 !important;
      border-color: #f5f5f5 !important;
    }
  .container {
    padding-top: -30px !important;
  }
.authenticator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.help-text{
    position: absolute;
    bottom: 7%;
    background: white;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
}
.help-text a{
    color: #ff9900!important;
}

  /* Transitions ---------------------------------------------------------- */
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }

  .fade-leave-to {
    opacity: 0;
  }
  .login-logo {
  /* background: white; */
  width: 100%;
  height: 120px;
  display: flex;
  position: absolute;
  top: 2%;
  left: 0;
  z-index: 1;
}


  .slide-fade-enter {
    transform: translateY(50px);
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.8s ease-out;
  }

  .slide-fade-leave-to {
    opacity: 0;
  }
  .description{
    padding: 15px;
    padding-top: 5px;
    margin-bottom: 0px!important;
  }
  .description{
    padding: 15px;
    padding-top: 10px;
}
.errorMessage{
    background: white;
    color: red;
    padding: 10px;
}
.errorBorder{
    border: 1px solid red;
}
.hidden{
    display: none;
}
.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
  min-height: 100px;
  visibility: visible;
  flex-basis: 100%!important;
  width: 100%;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  /* Increases font size on all tables on mobile */
   .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row td {
    font-size: 1.2rem;
  }
  /* Colours alternative table rows on mobile */
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(even) td {
    background: #F5F5F5;
  }
    /* All table rows */
  /* tbody tr:nth-of-type(odd) {
   background-color: rgba(242, 102, 66, .03);
  } */


/* Mobile L and < */
/* @media only screen and (max-width: 425px) {
  tbody tr:nth-of-type(odd) td {
   background-color: rgba(242, 102, 66, .05);
  }
} */
.home {
  background-image: url("../src/assets/background.png");
  background-position: center top;
  background-size: cover;
  overflow: hidden;
  height: 100%;
  width:100%;
}

</style>
