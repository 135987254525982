import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    permissions: null
  },
  mutations: {
    SET_PERMISSIONS(state, data) {
      state.permissions = data
    }
  },
	actions :  {
    listPermissions({commit, rootState}){
        const payload = {
            headers: {
                token : rootState.user.LoginToken
            }
        };
      API.get('apiKeysMainRest', `/get?query=/${rootState.user.ClientName}/permissions`, payload)
      .then(res => {
        commit('SET_PERMISSIONS', res.data.Data)
      })
    }
  },
  getters:{
      getPermissions: state => {
        return state.permissions;
      }
  }
}
