<template>
  <div>
    <p class="advisory-text">*New API keys and modifications to existing API keys can take a few minutes to update on agentOS before they will appear here. This list can be reloaded using the <v-icon>mdi-reload</v-icon> button if your changes have not yet appeared.</p>    
    <div class="spinner-container" v-if='keysLoading'>
      <vue-spinner size="massive" message="Loading your API keys, this might take a moment"/>
    </div>
    <div v-else>
      <h2>Active Keys</h2>
      <KeyCard v-for="item in apiKeys" :key="item.Key" :apiKey = "item" />
      <h2>Expiring Keys</h2>
      <p>These keys have been revoked and will be permanently deleted after the indicated expiry date (2 weeks from being revoked).</p>
      <KeyCard v-for="item in revokedKeys" :key="item.Key" :apiKey = "item" />
      <v-btn
        class="mx-2 new-key-button"
        fab
        dark
        color="#343351"
        @click="createKeyNav()"
      > 
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
        <v-btn
        class="mx-2 refresh-keys-button"
        fab
        dark
        color="#343351"
        @click="reloadApiKeys()"
      > 
        <v-icon dark>
          mdi-reload
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import KeyCard from '../components/KeyCard.vue'
import {mapGetters} from 'vuex'
import Spinner from 'vue-simple-spinner'

export default {
  components:{
    KeyCard,
    vueSpinner: Spinner
  },
  created() {
    if(!this.pricingApproved)
      this.$router.push('/pricing');
    this.$store.dispatch("Keys/listApiKeys");
    this.$store.dispatch("Keys/listRevokedKeys");
    this.$store.dispatch("Permissions/listPermissions");
  },
  computed: {
    ...mapGetters({
      apiKeys : 'Keys/getApiKeys',
      revokedKeys: 'Keys/getRevokedKeys',
      keysLoading : 'Keys/areKeysLoading',
      pricingApproved : 'pricingApproved'
    })
  },
  data() {
    return {
    }
  },
  methods:{
      createKeyNav(){
          this.$router.push('/createkey')
      },
      reloadApiKeys(){
    this.$store.dispatch("Keys/listApiKeys");
    this.$store.dispatch("Keys/listRevokedKeys");
      }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
  /* Images from https://www.pexels.com/ */
  background-position: center;
  background-size: cover;
}
.new-key-button{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1; 
}
.refresh-keys-button{
    position: fixed;
    bottom: 10px;
    right: 80px;
    z-index: 1; 
}
.advisory-text{
  color:grey;
  font-size: 10pt;
}
</style>