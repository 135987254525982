import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    apiKeys: null,
    revokedKeys: null,
    // Keys loading by default:-
    keysLoading : true,
  },
  mutations: {
    SET_API_KEYS(state, data) {
      state.apiKeys = data
    },
    SET_REVOKED_KEYS(state, data) {
      state.revokedKeys = data
    },
    SET_KEY_LOAD_STATE(state, data){
      state.keysLoading = data
    }
  },
	actions :  {
    listRevokedKeys({commit, rootState}){
      commit('SET_KEY_LOAD_STATE', true)
      const payload = {
          headers: {
              token : rootState.user.LoginToken
          }
      };
    API.get('apiKeysMainRest', `/get?query=/${rootState.user.ClientName}/keys/expiring`, payload)
    .then(res => {
      commit('SET_REVOKED_KEYS', res.data.Data)
      commit('SET_KEY_LOAD_STATE', false)
    })
  },
    listApiKeys({commit, rootState}){
      commit('SET_KEY_LOAD_STATE', true)
        const payload = {
            headers: {
                token : rootState.user.LoginToken
            }
        };
      API.get('apiKeysMainRest', `/get?query=/${rootState.user.ClientName}/keys`, payload)
      .then(res => {
        commit('SET_API_KEYS', res.data.Data)
        commit('SET_KEY_LOAD_STATE', false)
      })
    },
    createKey({rootState, dispatch}, payload){
      const postParams = {
        body: {
          body:{
          Integrator: payload.Integration,
          AdditionalNotes: payload.AdditionalNotes,
          Permissions: payload.Permissions,
          BranchID: payload.BranchID
          },
          token: rootState.user.LoginToken
        }
      }
      API.post('apiKeysMainRest', `/post?query=/${rootState.user.ClientName}/keys/create`, postParams)
        .then(
          dispatch("listApiKeys")
        )
    },
    updateKey({rootState, dispatch,commit}, payload){
      const postParams = {
        body: {
          body:{
          ApiKey: payload.ApiKey,
          Permissions: payload.Permissions,
          AdditionalNotes : payload.AdditionalNotes
          },
          token: rootState.user.LoginToken
        }
      }
      commit('SET_KEY_LOAD_STATE', true)
      API.post('apiKeysMainRest', `/patch?query=/${rootState.user.ClientName}/keys/update`, postParams)
        .then(
          setTimeout(() => {
            dispatch("listApiKeys")
          }, 2000)
          
        )
    },
    reinstateKey({rootState, dispatch, commit}, payload){
      const postParams = {
        body: {
          body:{
          ApiKey: payload.ApiKey
          },
          token: rootState.user.LoginToken
        }
      }
      commit('SET_KEY_LOAD_STATE', true)
      API.post('apiKeysMainRest', `/patch?query=/${rootState.user.ClientName}/keys/reinstate`, postParams)
        .then(() => {
          setTimeout(() => {
            dispatch("listApiKeys"),
            dispatch("listRevokedKeys")
          }, 2000)
        })
    },
    
    revokeKey({rootState, dispatch, commit}, payload){
      const postParams = {
        body: {
          body:{
          ApiKey: payload.ApiKey
          },
          token: rootState.user.LoginToken
        }
      }
      commit('SET_KEY_LOAD_STATE', true)
      API.post('apiKeysMainRest', `/delete?query=/${rootState.user.ClientName}/keys/revoke`, postParams)
        .then(() => {
          setTimeout(() => {
            dispatch("listApiKeys"),
            dispatch("listRevokedKeys")
          }, 2000)
        });
    }, 
  },
  
  getters:{
      getApiKeys: state => {
        return state.apiKeys;
      },
      getRevokedKeys: state => {
        return state.revokedKeys;
      },
      areKeysLoading(state) {
        return state.keysLoading
      },
  }
}
